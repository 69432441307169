
import { defineComponent, PropType, onMounted, ref } from "vue";
import { Player } from "@/types/player";
// import useMovement from "@/hooks/pieceMovement";

export default defineComponent({
  props: {
    players: {
      type: Object as PropType<Player[]>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
});

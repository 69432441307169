
import { defineComponent, computed, reactive } from "vue";
// import board_data from "./board-data.json";
import { useBoard } from "@/store/board";
import { useSettings } from "@/store/settings";

import { board_property } from "@/types/board";
import { includes } from "lodash";

export default defineComponent({
  emits: ["showPropertyDetails"],
  setup(_props, ctx) {
    const board = useBoard();
    const settings = useSettings();
    let houseLabel = reactive([
      "-",
      "bg-green-500",
      "bg-orange-500",
      "bg-blue-500",
      "bg-purple-500",
      "bg-red-500",
    ]);

    function getCurrentPiecePrice(boardProp: board_property) {
      if (boardProp.owner !== null) {
        if (boardProp.property) {
          if (typeof boardProp.rent === "object") {
            if (boardProp.hotel_count === 1) {
              return boardProp.hotel_cost;
            }
            if (boardProp.house_count === 4) {
              return boardProp.rent.rentH4;
            }
            if (boardProp.house_count === 3) {
              return boardProp.rent.rentH3;
            }
            if (boardProp.house_count === 2) {
              return boardProp.rent.rentH2;
            }
            if (boardProp.house_count === 1) {
              return boardProp.rent.rentH1;
            }
            if (checkPropertyGroupHasSameOwner(boardProp)) {
              return boardProp.rent.rentWC;
            }

            return boardProp.rent.rent;
          }
        }
        if (boardProp.utility) {
          return boardProp.rent;
        }
      }
    }
    function checkPropertyGroupHasSameOwner(boardProp: board_property) {
      if (boardProp.owner !== null) {
        let currentBoardGroup = boardProp.property_group;
        let ownerIds = currentBoardGroup.map((elem) => {
          let owner = board.getBoardProp(elem)?.owner;
          if (owner) {
            return owner.id;
          }
          return owner;
        });

        return allAreEqual(ownerIds);
      }
    }
    function allAreEqual(array: any) {
      if (array.length > 1) {
        const result = new Set(array).size === 1;

        return result;
      } else {
        return false;
      }
    }

    let toggleGrayScale = computed(() => {
      return settings.gary_board;
    });

    function includesInArray(arrItem: number[], item: number) {
      return includes(arrItem, item);
    }

    function boardClicking(item: board_property) {
      // debugger;
      if (item.house_count < 4) {
        board.addHouseCount(item.board_position);
        if (!settings.gary_board) return;
        settings.selectedItem.push(item);
      }
    }

    function showBoardDetail(item: board_property) {
      ctx.emit("showPropertyDetails", item);
    }

    return {
      boardData: board.board.List,
      getCurrentPiecePrice,
      toggleGrayScale,
      includesInArray,
      houseLabel,
      boardClicking,
      showBoardDetail,
    };
  },
});

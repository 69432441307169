import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c93ced"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "canvas-element",
  class: "dice-class",
  width: "500",
  height: "500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", _hoisted_1))
}

import { reactive, defineComponent } from "vue";
import PlayerCard from "@/components/PlayerCard.vue";
import { usePlayerStore } from "@/store/player";
import { Icon } from "@iconify/vue";
import { Player } from "@/types/player";

type stateObj = {
  showModal: boolean;
  player: Player | null;
  value: number;
};

export default defineComponent({
  components: {
    Icon,
    PlayerCard,
  },
  setup() {
    const Players = usePlayerStore();
    const state = reactive<stateObj>({
      showModal: false,
      player: null,
      value: 0,
    });

    function show(status = true, player: Player) {
      state.showModal = status;
      console.log(player);
      state.player = player;
    }

    return {
      state,
      show,
      Players,
    };
  },
});


import { defineComponent, ref } from "vue";
import { Icon } from "@iconify/vue";
import { Howler } from "howler";

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    let muted = ref(false);
    function toggleSound() {
      muted.value = !muted.value;
      if (muted.value) {
        Howler.mute(true);
      } else {
        Howler.mute(false);
      }
    }
    return {
      muted,
      toggleSound,
    };
  },
});

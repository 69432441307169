import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white border-4 flex font-extralight rounded-md shadow my-2 border-blue-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.muted)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSound())),
          icon: "octicon:unmute-16",
          class: "mx-2 text-blue-500",
          style: {"font-size":"40px"}
        }))
      : (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleSound())),
          icon: "bx:volume-mute",
          class: "mx-2 text-blue-500",
          style: {"font-size":"40px"}
        }))
  ]))
}